<template>
  <div class="main">
    <div class="header">
      <div class="title">
        <img src="../assets/img/logo.png" style="width: 70px; height: 70px;">
        <div style = "display: flex;">
          <label style="font-size: 22px; cursor:pointer;" @click="toHome">首页</label>
          <div style="font-size: 22px; margin-left: 100px; color: blueviolet">关于我们</div>
        </div>
      </div>
      <img src="../assets/img/about.jpeg" style="width: 70%; margin-left: 50px; margin-top: 30px">
      <div style="margin-left: 50px; margin-top: 30px; font-size: 25px">公司简介</div>
      <div style="font-size: 18px; margin-left: 50px; margin-right: 50px; margin-top: 20px; width: 70%">广州一快科技有限公司，专业专注于方便出行、安全出行，依据消费者之需，全面布局“人找车”的出行战略，为消费者提供出行一站式整体解决方案，助力交通出行化繁为简。
        一快科技自创立以来，始终坚持“同乡出行，一路同行”的理念定位，以“方便同乡，助力同乡”作为内核和驱动力，致力于为每个消费者量身定制高品质的出行计划。
        2021年，一快科技正式提出“一路同行”品牌主张，倡导“安全、舒适、便捷、亲民”，鼓励每个人做自己生活的主导者，以“我”为生活原点，以“我”为出行起点，注重用舒适安全的体验，助力消费者实现自我生活主张，让安全出行成为当下年轻人彰显个性、自如享受安全舒适的新方式。</div>
      <div style="margin-left: 50px; margin-top: 30px; font-size: 25px">公司地址</div>
      <div style="font-size: 18px; margin-left: 50px; margin-right: 50px; margin-top: 20px; width: 70%">广州市天河区龙口中路194号101房自编4-17房</div>
      <div style="align-items: center; width: 100%; justify-content: center; display: flex; margin-top: 50px">
        <div style="font-size: 12px">Copyright © 2021 yikuai Inc. All Rights Reserved | </div>
        <a href="http://beian.miit.gov.cn/" style="font-size: 12px; margin-left: 5px">粤ICP备2021137428号</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "About",
  methods:{
    toHome(){
      return this.$router.replace({path:'/home'});
    }
  }
}
</script>

<style scoped>
  .main {
    width: 100%;
  }

  .header {
    width: 100%;
    height: 100%;
  }

  .title {
    width: 100% - 200px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    margin-left: 50px;
    margin-right: 100px;
    padding-top: 20px;
    flex-wrap: wrap;
    align-items: center;
  }
</style>
